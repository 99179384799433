<template>
    <v-dialog v-model="dialog" content-class="fill-height" persistent max-width="900px">
        <v-card v-if="dialog" class="fill-height d-flex flex-column">
            <v-card-title>
                <span class="headline">Enviar emails</span>
            </v-card-title>
            <v-card-text class="pb-0 flex-grow-1 overflow-y-auto d-flex flex-column">
                <v-row class="flex-grow-0">
                    <v-col cols="4">
                        <v-form ref="form1" v-model="form1Valid" lazy-validation>
                            <v-menu v-model="dataLimiteMenu"
                                    :close-on-content-click="false"
                                    transition="scale-transition"
                                    offset-y :disabled="enviando"
                                    max-width="290px">
                                <template v-slot:activator="{ on }">
                                    <v-text-field
                                            v-model="dataLimiteFormated"
                                            label="Data limite para entrega dos documentos"
                                            readonly hide-details :disabled="enviando"
                                            v-on="on"
                                    ></v-text-field>
                                </template>
                                <v-date-picker v-model="dataLimite"
                                               no-title :min="$moment(new Date()).format('YYYY-MM-DD')"
                                               locale="pt-br" required
                                               @input="dataLimiteMenu = false">
                                </v-date-picker>
                            </v-menu>
                        </v-form>
                    </v-col>
                    <v-col class="caption" v-if="fiscalizacao">
                        <v-row no-gutters v-if="!enviando">
                            <v-col class="shrink"><span class="text-no-wrap">Será enviado para:</span></v-col>
                            <v-col class="ml-2">
                                {{codigoAgencia}}.adm@bradesco.com.br<br/>
                                {{codigoAgencia}}.gerencia@bradesco.com.br

                                <v-tooltip bottom v-if="agenciaContatosEmails.length > 0">
                                    <template v-slot:activator="{ on, attrs }">
                                        <v-chip v-on="on" v-bind="attrs" label color="green" x-small dark class="px-2 ml-2">
                                            + {{agenciaContatosEmails.length}}
                                        </v-chip>
                                    </template>
                                    <div>
                                        <template v-for="email of agenciaContatosEmails">
                                            <span class="d-block caption" :key="email">{{email}}</span>
                                        </template>
                                    </div>
                                </v-tooltip>
                            </v-col>
                        </v-row>
                    </v-col>

                </v-row>
                <v-row no-gutters class="flex-grow-1 overflow-hidden">
                    <v-col cols="4" class="d-flex flex-column fill-height pr-0 ">
                        <v-switch v-model="showSent" dense class="mt-0 grey lighten-3 pa-2" hide-details label="Mostrar enviados"></v-switch>
                        <div class="overflow-auto " style="height: inherit">
                            <v-list :disabled="enviando" >
                                <v-list-item-group v-model="fiscalizacaoSelecionada">
                                    <template v-for="(fiscalizacao, index) in fiscalizacoes">
                                        <v-list-item :key="fiscalizacao.id"
                                                 :disabled="!fiscalizacao.financiamento.agencia || !fiscalizacao.supervisedBy"
                                                 v-if="showSent || fiscalizacao.emailStatus !== 2"
                                                 dense class="pl-0"
                                                 >
                                        <v-list-item-avatar size="30">
                                            <v-icon small class="light-green lighten-3" v-text="getItemIcon(fiscalizacao.tipo)"></v-icon>
                                        </v-list-item-avatar>
                                        <v-list-item-content>
                                            <v-list-item-title class="font-weight-regular" >{{fiscalizacao.financiamento.mutuario}}</v-list-item-title>
                                            <div >
                                                <template v-if="!fiscalizacao.financiamento.agencia || !fiscalizacao.supervisedBy || !fiscalizacao.financiamento.origemRecursos">
                                                    <v-chip v-if="!fiscalizacao.financiamento.agencia" x-small label color="yellow darken-2" text-color="white" class="mr-1 font-weight-bold px-2">
                                                        SEM AGÊNCIA
                                                    </v-chip>
                                                    <v-chip v-if="!fiscalizacao.supervisedBy" x-small label color="yellow darken-2" text-color="white" class="mr-1 font-weight-bold px-2">
                                                        SEM RESPONSÁVEL
                                                    </v-chip>
                                                    <v-chip v-if="!fiscalizacao.financiamento.origemRecursos" x-small label color="yellow darken-2" text-color="white" class="mr-1 font-weight-bold px-2">
                                                        SEM ORIGEM RECURSOS
                                                    </v-chip>
                                                </template>

                                                <template v-else>
                                                    <v-chip v-if="fiscalizacao.emailStatus === 0" x-small label color="grey lighten-1" text-color="white" class="mr-1 font-weight-bold px-2">
                                                        AGUARDANDO
                                                    </v-chip>
                                                    <v-chip v-if="fiscalizacao.emailStatus === 1" x-small label color="blue" text-color="white" class="mr-1 font-weight-bold px-2">
                                                        ENVIANDO...
                                                    </v-chip>
                                                    <v-chip v-if="fiscalizacao.emailStatus === 2" x-small label color="green" text-color="white" class="mr-1 font-weight-bold px-2">
                                                        ENVIADO
                                                    </v-chip>
                                                    <v-chip v-if="fiscalizacao.emailStatus === 3" x-small label color="red" text-color="white" class="mr-1 font-weight-bold px-2">
                                                        ERRO
                                                    </v-chip>
                                                </template>

                                            </div>
                                        </v-list-item-content>
                                    </v-list-item>
                                    </template>
                                </v-list-item-group>
                            </v-list>
                        </div>
                        <div class="py-2 caption text-uppercase">
                            {{fiscalizacoes.length}} emails
                        </div>

                    </v-col>
                    <v-col class="ml-2 grow overflow-y-auto overflow-x-hidden fill-height pr-0">
                        <v-row v-if="fiscalizacao" style="height: inherit">
                            <div v-if="!enviando" class="pa-4 email-preview">
                                <email-solicitao-documento-template :fiscalizacao="fiscalizacao" :data-limite="dataLimite" />
                            </div>
                            <div v-else class="d-flex flex-column align-center justify-center" style="width: 100%">
                                <div class="text-center" style="width: 300px">
                                    <v-progress-linear rounded color="orange" :background-opacity="0" stream buffer-value="0" :value="Math.floor(totalEnviado / fiscalizacoes.length*100)" class="mb-1"  />
                                    <span class="">Enviado {{totalEnviado}} de {{fiscalizacoesValidas.length}}</span>
                                </div>
                            </div>
                        </v-row>
                    </v-col>
                </v-row>
            </v-card-text>
            <v-card-actions>
                <div class="pa-3" style="width: 100%">
                    <v-btn text @click="closeDialog" >Cancelar</v-btn>

                    <div class="float-right">
                        <v-btn color="primary" text depressed :disabled="enviando || fiscalizacoesValidas < 1" @click="iniciarEnvio" >
                            Enviar
                        </v-btn>
                    </div>

                </div>
            </v-card-actions>
            <v-overlay absolute :value="loading" color="white">
                <v-progress-circular :size="100" :width="7" color="deep-orange" indeterminate></v-progress-circular>
            </v-overlay>
        </v-card>
    </v-dialog>
</template>

<script>
    import {FiscalizacaoQueries} from '../../../queries/FiscalizacaoQueries';
    import gql from "graphql-tag";
    import {getIconByFiscalizacaoTipo} from "../../../assets/FiscaizacaoUtil";
    // @ts-ignore
    import EmailSolicitaoDocumentoTemplate from "./fragments/email_templates/EmailSolicitacaoDocumentoTemplate";
    export default {
        name: "EnviarBatchEmailDialog",
        components: {EmailSolicitaoDocumentoTemplate},
        watch:{
            fiscalizacaoSelecionada(index){
                this.fiscalizacao = this.fiscalizacoes[index]
            },
            totalEnviado(value){
                if(this.enviando && this.fiscalizacoesValidas.length === value){
                    this.$notify({text: 'Emails enviados com sucesso!', type: 'success'});
                    this.$emit('completed');
                    this.enviando = false;
                    this.closeDialog();
                }
            }
        },
        computed:{
            agenciaContatosEmails(){
                return this.fiscalizacao.financiamento.agencia.contatos
                    .filter(c => c.emailFiscalizacao)
                    .map(c => c.email)
            },
            dataLimiteFormated: {
                get() {
                    return this.$moment(this.dataLimite).format('DD/MM/YYYY');
                },
                set(newValue) {
                    if (newValue) {
                        this.dataLimite = newValue;
                    }
                },
            },
            codigoAgencia(){
                let codigo = this.fiscalizacao.financiamento.agencia.codigo;
                if(codigo.length === 3){
                    codigo = '0' + codigo;
                }
                return codigo;
            },
            fiscalizacoesValidas(){
                return this.fiscalizacoes.filter((fiscalizacao) => fiscalizacao.financiamento.agencia && fiscalizacao.supervisedBy && fiscalizacao.financiamento.origemRecursos)
            }
        },
        data() {
            return {
                dialog: false,
                dataLimite: null,
                dataLimiteMenu: false,
                fiscalizacoes: [],
                fiscalizacao: null,
                loading: false,
                totalEnviado: 0,
                enviando: false,
                showSent: false,
                form1Valid: true,
                fiscalizacaoSelecionada: null,
            }
        },
        methods: {
            openDialog(currentFilter) {
                this.dialog = true;
                this.dataLimite = this.getDataLimite();
                this.getFiscalizacoes(currentFilter);
            },
            closeDialog() {
                if(this.enviando){
                    this.$dialog.confirm({
                        title: 'Atenção',
                        text: 'Deseja realmente cancelar o envio dos emails?'
                    }).then((result) => {
                        if (result) {
                            this.dialog = false;
                            this.enviando = false;
                            this.totalEnviado = 0;
                            this.$emit('canceled');
                        }
                    })
                    return
                }
                this.dialog = false;
                this.totalEnviado = 0;
                this.fiscalizacao = null;
            },
            /*showPreview(fiscalizacao) {
                this.fiscalizacao = fiscalizacao;
            },*/
            getItemIcon(tipo){
                return getIconByFiscalizacaoTipo(tipo);
            },
            getFiscalizacoes(filter) {
                console.log('getFiscalizacoes.filter', filter)
                this.loading = true;

                this.$apollo.query({
                    query: gql`query ($filter: SearchFilterInput!){
                        fiscalizacoes:fiscalizacoesParaEmail(filter: $filter){
                            id
                            emailEnviadoAt
                            tipo{
                                id
                                nome
                            }
                            supervisedBy{
                                nome
                                sobrenome
                            }
                            financiamento{
                                empreendimento{
                                    id
                                }
                                finalidade{
                                    id
                                }
                                origemRecursos{
                                    id
                                }
                                operacao
                                operacaoDigito
                                cedula
                                mutuario
                                agencia{
                                    codigo
                                    contatos{
                                        email
                                        emailFiscalizacao
                                    }
                                }
                            }
                        }
                    }`,
                    variables: {
                        filter: filter,
                    },
                }).then((result) => {
                    this.fiscalizacoes = result.data.fiscalizacoes.map((fiscalizacao, index) => {
                        fiscalizacao.emailStatus = 0;
                        return fiscalizacao;
                    });
                    this.selecionaPrimeiroValido(this.fiscalizacoes)
                    this.loading = false;
                });
            },
            selecionaPrimeiroValido(fiscalizacoes){
                this.fiscalizacao = fiscalizacoes.find((fiscalizacao) => fiscalizacao.financiamento.agencia !== null && fiscalizacao.supervisedBy !== null && fiscalizacao.financiamento.origemRecursos != null);
            },
            getDataLimite(){
                const daysToAdd = 6;
                let date = this.$moment(new Date());
                date = this.$moment(date.businessAdd(daysToAdd)._d);

                return date.format('YYYY-MM-DD');
            },
            async iniciarEnvio(){
                if(!this.$refs.form1.validate()){
                    return false
                }

                this.enviando = true;

                for(let i = 0; i < this.fiscalizacoesValidas.length && this.enviando; i++){
                    this.fiscalizacao = this.fiscalizacoesValidas[i];
                    this.enviar(this.fiscalizacao);
                }
            },
            enviar(fiscalizacao){
                fiscalizacao.emailStatus = 1;
                /*return new Promise(((resolve, reject) => {
                    setTimeout(()=>{
                        fiscalizacao.emailStatus = 2
                        this.totalEnviado++;
                        resolve()
                    }, Math.random() * 5000)
                }))*/
                return new Promise((resolve, reject) => {
                    this.$apollo.mutate({
                        mutation: FiscalizacaoQueries.enviarEmailAgencia,
                        variables: {
                            id: fiscalizacao.id,
                            dataLimite: new Date(this.dataLimite).getTime()
                        },
                    }).then((result) => {
                        fiscalizacao.emailStatus = 2
                        resolve();
                    }).catch(error => {
                        fiscalizacao.emailStatus = 3;
                        reject()
                    }).finally(()=>{
                        this.totalEnviado++;
                    })
                });
            },
        }
    }
</script>

<style scoped>
    .email-preview{
        /*border: 0.5px solid #b6b6b6;*/
        background-color: #f9f9f9;
    }
</style>