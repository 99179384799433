<template>
    <div>
        <v-card outlined  class="servicos-card teal" dark>
            <v-card-title class="pb-0">
                <span class="mr-2">Serviços</span>
                <v-chip small light :to="{name:'servicos'}">Ver Tudo<v-icon right small>mdi-chevron-right</v-icon></v-chip>
            </v-card-title>
            <v-card-text class="pa-0">
                <div class="custom-container">

                    <div class="body">
                        <v-simple-table fixed-header dense v-if="servicos.length > 0" height="500" dark>
                            <template v-slot:default>
                                <thead>
                                <tr>
                                    <th class="text-left">Agência</th>
                                    <th class="text-left">Descrição</th>
                                    <th class="text-right">Valor</th>
                                </tr>
                                </thead>
                                <tbody>
                                <tr v-for="(servico, index) in servicos" :key="index">
                                    <td class="caption">
                                        <router-link class="caption white--text" :to="{ name: 'agencia_view', params: { id: parseInt(servico.agencia.id) }}">{{servico.agencia.codigo}}</router-link>
                                        <span> {{servico.agencia.cidade.nome}}-{{servico.agencia.cidade.estado.sigla}}</span>
                                    </td>
                                    <td class="caption"><a class="white--text" href="" @click.prevent="viewServico(servico, index)">{{ totalFiscalizacoes(servico.fiscalizacoes) }}</a></td>
                                    <td class="text-right caption">{{servico.valorTotalServico | formatNumber}}</td>
                                </tr>
                                </tbody>
                            </template>
                        </v-simple-table>
                        <div v-else class="text-center pa-2">
                            <span >Nenhum serviço criado!</span>
                        </div>
                    </div>
                    <div class="footer">
                        <v-row class="mx-2" v-if="servicos.length > 0">
                            <v-col>Total</v-col>
                            <v-col class="text-right font-weight-bold">{{_.sumBy(servicos, (s) => s.valorTotalServico) | formatNumber}}</v-col>
                        </v-row>
                        <div class="pa-2">
                            <v-btn v-if="$parent.$parent.$parent.$parent.usuario.admin || $parent.$parent.$parent.$parent.usuario.id === 14"
                                   :disabled="servicos.length === 0" light block @click="$refs.cobrarServicosDialog.openDialog(null)">
                                Cobrar {{servicos.length}} Serviços
                            </v-btn>
                        </div>
                    </div>
                </div>
            </v-card-text>
        </v-card>
        <servico-dialog ref="servicoDialog" />
        <cobrar-servicos-dialog ref="cobrarServicosDialog" />
    </div>
</template>

<script>
import gql from "graphql-tag";
// @ts-ignore
import ServicoDialog from "../../servico/fiscalizacao/servico/ServicoDialog";
import CobrarServicosDialog from "@/views/financeiro/CobrarServicosDialog";

export default {
    name: "ServicosCard",
    data() {
        return {
            loading: false,
            servicos: [],
        }
    },
    components:{
        ServicoDialog,
        CobrarServicosDialog
    },
    mounted() {
        this.getServicosDeLoteNaoFinalizado();
    },
    methods:{
        totalFiscalizacoes(fiscalizacoes) {
            const total = fiscalizacoes.length;
            let label = ' fiscalização';
            if(total > 1){
                label = ' fiscalizações';
            }
            return total + label;
        },
        viewServico(servico, index){
            if(servico.emissaoStatus === 3){
                this.$refs.notaFiscalErrorDialog.openDialog(servico.errors);
                return;
            }
            this.$refs.servicoDialog.openDialog(servico, index, true, false);
        },
        getServicosDeLoteNaoFinalizado(){
            this.loading = true;
            return this.$apollo.query({
                query: gql`query{
                        servicos:servicosDeLoteNaoFinalizado{
                            id
                            distanciaExcedida
                            valorTotalDistanciaExcedida
                            valorTotalServico
                            agencia{
                                id
                                nome
                                codigo
                                endereco
                                numero
                                bairro
                                cnpj
                                cep
                                cidade{
                                    nome
                                    codigoIbge
                                    estado{
                                        sigla
                                    }
                                }
                            }
                            fiscalizacoes{
                                id
                                distanciaPercorrida
                                financiamento{
                                    mutuario
                                    valor
                                }
                            }
                            notaFiscal{
                                id
                                dataEmissao
                                numero
                                valorServicos
                            }
                            loteServico{
                                id
                            }
                        }
                    }`,
            }).then((result) => {
                this.loteId = result.data.servicos[0].loteServico.id
                this.servicos = result.data.servicos;
                //this.totalServicos = result.data.servicosPorLote.totalCount
                this.loading = false;
            });
        },
    }
}
</script>

<style scoped>
.custom-container .v-data-table thead th,
.custom-container .v-data-table {
    background: #009688 !important
}

.custom-container .v-data-table tbody > tr:hover{
    background: #02776c !important
}

</style>