import gql from 'graphql-tag';

export class AreaQueries {
    public static updateCoordenadas = gql`mutation ($fiscalizacaoId:Int!, $coordenadas: String){
        updateCoordenadas(fiscalizacao_id: $fiscalizacaoId, coordenadas: $coordenadas){
            id
            coordenadas
        }
    }`;
}
