import { render, staticRenderFns } from "./BaixarCoordenadasDialog.vue?vue&type=template&id=4286c6c7&scoped=true&"
import script from "./BaixarCoordenadasDialog.vue?vue&type=script&lang=js&"
export * from "./BaixarCoordenadasDialog.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "4286c6c7",
  null
  
)

export default component.exports