<template>
    <v-card outlined >
        <v-card-title>
            <span class="mr-2" style="text-transform: capitalize">{{titulo}}</span>
            <v-btn icon @click="$refs.calendar.prev()">
                <v-icon>mdi-chevron-left</v-icon>
            </v-btn>
            <v-btn icon @click="$refs.calendar.next()">
                <v-icon>mdi-chevron-right</v-icon>
            </v-btn>
        </v-card-title>
        <v-card-text >
            <v-calendar ref="calendar" v-model="dataAtual" type="month" :weekdays="[1, 2, 3, 4, 5, 6, 0]"
            locale="pt-br" color="primary" @change="onMonthChanged">
                <template v-slot:day="{index, day, date, outside}">
                    <v-menu v-if="events.some(e => e.dia === day) && !outside"
                        open-on-hover offset-y @input="onMenuInput($event, date)">
                        <template v-slot:activator="{ on, attrs }">
<!--                            <v-sheet elevation="1" height="40px" class="red"
                                     v-bind="attrs"
                                     v-on="on">{{day}}aaiduhiufHoIS poihfiuagofidgIfsfsag  fsef a</v-sheet>-->

<!--                            <v-chip>
                                {{events[index]}}
                            </v-chip>-->
                            <v-chip outlined color="primary" dark v-bind="attrs" v-on="on">
                                {{events.find(e => e.dia == day).total}} fiscaliza;cões
                            </v-chip>
                        </template>
                        <div style="max-height: 200px">
                            <v-list dense>
                                <template v-for="(fiscalizacao, i) in fiscalizacoesporData">
                                    <v-list-item :key="i"  :to="{ name: 'fiscalizacao_view', params: { id: parseInt(fiscalizacao.id) }}" >
                                        <v-list-item-avatar size="38" >
                                            <v-icon dense class="light-green lighten-3" v-text="getItemIcon(fiscalizacao.tipo)"></v-icon>
                                        </v-list-item-avatar>
                                        <v-list-item-content>
                                            <v-list-item-title>{{fiscalizacao.financiamento.mutuario}}</v-list-item-title>
                                            <v-list-item-subtitle>{{fiscalizacao.financiamento.empreendimento.descricao}}</v-list-item-subtitle>
                                        </v-list-item-content>

                                    </v-list-item>
                                </template>
                            </v-list>
                        </div>
                    </v-menu>
                </template>
            </v-calendar>
        </v-card-text>
    </v-card>
</template>

<script>
import gql from "graphql-tag";
import {getIconByFiscalizacaoTipo} from "../../../../../assets/FiscaizacaoUtil";

export default {
    name: "CalendarioFiscalizacoesCard",
    data(){
        return{
            events:[],
            dataAtual:null,
            titulo: null,
            fiscalizacoesporData:[],
            showMenu:false,
        }
    },
    mounted() {
        //console.log(this.$refs.calendar.title)
    },
    methods:{
        onMenuInput(event, date){
            if(event){
                this.getFiscalizacoesPorData(date)
            }
        },
        onMonthChanged(obj){
            this.titulo = this.$refs.calendar['title'];
            this.getFiscalizacoesFuturas(obj.start.month, obj.start.year);
        },
        getFiscalizacoesFuturas(mes, ano){
            this.loading = true;

            this.$apollo.query({
                query: gql`query($mes:Int!, $ano:Int!) {
                    calendarioFiscalizacoesPorMesAno(mes: $mes, ano: $ano) {
                        dia
                        total
                    }
                }`,
                variables: {mes, ano},
            }).then((result) => {
                this.events = []
                /*result.data.calendarioFiscalizacoesPorMesAno.forEach(fiscalizacoesPorDia => {
                    this.events.push({
                        name: fiscalizacoesPorDia.total + "fiscalizaćões",
                        start: `${ano}-${mes}-${fiscalizacoesPorDia.dia}`,
                        //end: second,
                        color: 'red',
                        timed: false,
                    })
                });*/
                this.events = result.data.calendarioFiscalizacoesPorMesAno
                this.loading = false;
            });
        },
        getFiscalizacoesPorData(data){
            this.fiscalizacoesporData = []
            this.loading = true;

            this.$apollo.query({
                query: gql`query($data:LocalDate!) {
                    fiscalizacoesFuturasPorData(data: $data) {
                        id
                        tipo {
                            nome
                        }
                        financiamento {
                            mutuario
                            empreendimento {
                                descricao
                            }
                        }
                    }
                }`,
                variables: {data},
            }).then((result) => {
                this.fiscalizacoesporData = result.data.fiscalizacoesFuturasPorData
                this.loading = false;
            });
        },
        getItemIcon(tipo){
            return getIconByFiscalizacaoTipo(tipo);
        },
    }
}
</script>

<style scoped>

</style>