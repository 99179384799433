<template>
    <page title="Fiscalizações">
        <v-breadcrumbs slot="breadcrumb" class="pa-0">
            <v-breadcrumbs-item exact :to="{name:'dashboard'}">Início</v-breadcrumbs-item>
        </v-breadcrumbs>
        <div slot="menu">
            <v-menu offset-y >
                <template v-slot:activator="{ on }">
                    <v-btn outlined style="border: solid thin rgba(0, 0, 0, 0.12)" v-on="on">
                        <v-icon left>mdi-cog</v-icon>
                        <span>Ações</span>
                        <v-icon right>mdi-chevron-down</v-icon>
                    </v-btn>
                </template>
                <v-list>
                    <v-list-item @click="openDividirFiscalizacoesDialog" v-if="$parent.$parent.usuario.admin">
                        <v-list-item-icon>
                            <v-icon>mdi-account-switch</v-icon>
                        </v-list-item-icon>
                        <v-list-item-title>Dividir fiscalizações</v-list-item-title>
                    </v-list-item>
                    <v-list-item @click="onUploadLoteLaudosButtonClicked" :disabled="uploadingLoteLaudos">
                        <v-list-item-icon>
                            <v-icon>mdi-folder-upload-outline</v-icon>
                        </v-list-item-icon>
                        <v-list-item-title>Enviar lote de laudos</v-list-item-title>
                    </v-list-item>
                    <v-list-item @click="baixarArquivosBanco" >
                        <v-list-item-icon>
                            <v-icon>mdi-file-download-outline</v-icon>
                        </v-list-item-icon>
                        <v-list-item-title>Baixar arquivos do banco</v-list-item-title>
                    </v-list-item>
                    <!--                    <v-list-item @click="enviarLaudosBanco" >
                                            <v-list-item-icon>
                                                <v-icon>mdi-file-send-outline</v-icon>
                                            </v-list-item-icon>
                                            <v-list-item-title>Enviar laudos para o banco</v-list-item-title>
                                        </v-list-item>-->
                    <v-list-item @click="importarOperacoes" >
                        <v-list-item-icon>
                            <v-icon>mdi-file-send-outline</v-icon>
                        </v-list-item-icon>
                        <v-list-item-title>Importar Operações</v-list-item-title>
                    </v-list-item>
                    <v-list-item @click="baixarCoordenadas" >
                        <v-list-item-icon>
                            <v-icon>mdi-file-download-outline</v-icon>
                        </v-list-item-icon>
                        <v-list-item-title>Baixar Coordenadas</v-list-item-title>
                    </v-list-item>
                </v-list>
            </v-menu>
            <!--            <v-btn v-if="$parent.$parent.usuario.admin || $parent.$parent.usuario.id === 14"
                               class="ml-2" text outlined :to="{name:'servicos'}">Serviços</v-btn>-->
            <input v-show="false" ref="inputFileUpload" type="file" multiple
                   @input="uploadLoteLaudos" >
        </div>

        <v-row>
            <v-col class="col-8">
                <calendario-fiscalizacoes-card />
                <resumo-fiscalizacoes-card :resumo="fiscalizacoesStatus" />
            </v-col>
            <v-col>
                <servicos-card />
            </v-col>
        </v-row>

        <dividir-fiscalizacoes-dialog ref="dividirFiscalizacoesDialog" @saved="refreshList" />


        <baixar-arquivos-banco-dialog ref="baixarArquivosBancoDialog" @completed="refreshList" />
        <!--        <enviar-laudos-banco-dialog ref="enviarLaudosBancoDialog" @completed="refreshList" />-->
        <baixar-coordenadas-dialog ref="baixarCoordenadasDialog" @completed="refreshList" />
    </page>
</template>

<script>
import page from "../../../components/Page";
import gql from "graphql-tag";
import ResumoFiscalizacoesCard from "./fragments/view/ResumoFiscalizacoesCard";
import BaixarArquivosBancoDialog from "./BaixarArquivosBancoDialog";
import EnviarBatchEmailDialog from "./EnviarBatchEmailDialog";
import DividirFiscalizacoesDialog from "./DividirFiscalizacoesDialog";
import BaixarCoordenadasDialog from "./BaixarCoordenadasDialog";
import ServicosCard from "../../financeiro/fragments/ServicosCard";
import CalendarioFiscalizacoesCard from "./fragments/view/CalendarioFiscalizacoesCard";

export default {
    name: "Dashboard",
    components:{
        CalendarioFiscalizacoesCard,
        ServicosCard,
        BaixarCoordenadasDialog,
        BaixarArquivosBancoDialog,
        EnviarBatchEmailDialog,
        DividirFiscalizacoesDialog,
        ResumoFiscalizacoesCard,
        page
    },
    data(){
        return {
            tab: null,
            fiscalizacoesStatus: {
                totalFiscalizacoes: '-',
                fiscalizacoesFinalizadas: '-',
                fiscalizacoesPendentes: '-',
                fiscalizacoesAtrasadas: '-',
            },
            uploadingLoteLaudos: false,
        }
    },
    methods: {
        onUploadLoteLaudosButtonClicked(){
            this.$refs.inputFileUpload.value = "";
            this.$refs.inputFileUpload.click();
        },
        openDividirFiscalizacoesDialog() {
            this.$refs.dividirFiscalizacoesDialog.openDialog();

        },
        openEnviarBatchEmailDialog(){
            this.$refs.enviarBatchEmailDialog.openDialog(this.formatFilterToRequest(this.currentFilter));
        },
        baixarArquivosBanco(){
            this.$refs.baixarArquivosBancoDialog.openDialog();
        },
        baixarCoordenadas(){
            this.$refs.baixarCoordenadasDialog.openDialog();
        },
        importarOperacoes(){
            this.$apollo.mutate({
                mutation: gql`mutation {
                        iniciaTarefaImportacaoOperacao{
                            id
                        }
                    }`,
            })
        },
        getFiscalizacoesStatus() {
            this.$apollo.query({
                query: gql`query {
                            fiscalizacoesStatus{
                                totalFiscalizacoes
                                fiscalizacoesFinalizadas
                                fiscalizacoesPendentes
                                fiscalizacoesAtrasadas
                            }
                        }`,
            }).then(result => {
                this.fiscalizacoesStatus = result.data.fiscalizacoesStatus;
            })
        },
        uploadLoteLaudos(e){
            let file = e.target.files[0]
            this.uploadingLoteLaudos = true;

            const formData = new FormData();
            formData.append('file', file);
            this.$axios.post('/fiscalizacoes/uploadLaudos', formData, {
                headers: {
                    // @ts-ignore
                    'Content-Type': `multipart/form-data; boundary=${formData._boundary}`,
                },
            }).then((result) => {
                let arquivos = result.data
                this.$notify({text: 'Lote enviado com sucesso!', type: 'success'});
            }).catch((error) => {
                this.$notify({text: 'Lote enviado com sucesso!', type: 'error'});
            })
                .finally(()=>{
                    this.uploadingLoteLaudos = false
                    this.refreshList();
                })
        },
        refreshList(){
            this.loading = true;
            //this.getList(this.currentFilter);
        },
    },
    mounted() {
        document.title = "Fiscalizações - JF Assessoria Rural"
        this.getFiscalizacoesStatus();
    }
}
</script>

<style scoped>

</style>