<template>
    <v-card outlined min-height="320" class="resumo-fiscalizacoes-card">
        <v-card-title class="pb-0">
            <span class="mr-2">Resumo</span>
        </v-card-title>
        <v-card-text class="pa-0">
            <v-chip-group v-model="tipoResumoSelecionado" mandatory class="ml-4" color="primary" >
                <v-chip small outlined :value="1"  color="red">{{resumo.fiscalizacoesAtrasadas}} Fiscalizações Pendentes</v-chip>
                <v-chip small outlined :value="2" >{{resumo.fiscalizacoesPendentes}} Pedidos de Prorrogação</v-chip>
                <v-chip small outlined :value="-1" :to="{name:'todas_fiscalizacoes'}">Ver Tudo<v-icon right small>mdi-chevron-right</v-icon></v-chip>
            </v-chip-group>
<!--            <div class="text-center mt-3" v-if="fiscalizacoes">
                <v-pagination v-model="page" total-visible="8"
                              :length="Math.ceil(totalCount / offset)"
                              @input="pageChanged"></v-pagination>
            </div>-->

            <v-simple-table dense class="resumo-fiscalizacoes-table" fixed-header height="200px">
                <template v-slot:default>
                    <thead>
                    <tr style="white-space: nowrap">
                        <th class="text-left shrink"></th>
                        <th class="text-left">Mutuário</th>
                        <th class="text-left">Empreendimento</th>
                        <th class="text-left">Agência</th>
                        <th class="text-left">Data Limite</th>
                        <th class="text-right" >Valor (R$)</th>
                    </tr>
                    </thead>
                    <tbody>
                    <template v-for="(fiscalizacao, index) in fiscalizacoes">
                        <tr :key="index">
                            <td class="shrink">
                                <v-avatar size="22">
                                    <v-icon class="light-green lighten-3" small v-text="getItemIcon(fiscalizacao.tipo)"></v-icon>
                                </v-avatar>
                            </td>
                            <td>
                                <router-link class="caption" :to="{ name: 'fiscalizacao_view', params: { id: parseInt(fiscalizacao.id) }}">{{ fiscalizacao.financiamento.mutuario }}</router-link>
                            </td>
                            <td class="caption ">
                                <span>{{fiscalizacao.financiamento.empreendimento.descricao}}</span>
                            </td>
                            <td class="caption ">
                                <template v-if="fiscalizacao.financiamento.agencia">
                                    <router-link class="caption" :to="{ name: 'agencia_view', params: { id: parseInt(fiscalizacao.financiamento.agencia.id) }}">{{fiscalizacao.financiamento.agencia.codigo}}</router-link>
                                    <span> {{fiscalizacao.financiamento.agencia.cidade.nome}}-{{fiscalizacao.financiamento.agencia.cidade.estado.sigla}}</span>
                                </template>
                                <span v-else>-</span>
                            </td>
                            <td>
                                <span>{{fiscalizacao.dataLimite | moment("DD/MM/YYYY")}}</span>
                            </td>
                            <td class="caption text-right">
                                <span>{{fiscalizacao.financiamento.valor | formatDecimal}}</span>
                            </td>
                        </tr>
                    </template>
                    </tbody>
                </template>
            </v-simple-table>

            <<!--div class="text-center mt-3" v-if="fiscalizacoes">
                <v-pagination v-model="page" total-visible="8"
                              :length="Math.ceil(totalCount / offset)"
                              @input="pageChanged"></v-pagination>
            </div>-->
        </v-card-text>
    </v-card>

</template>

<script>
import gql from "graphql-tag";
import {getFinalizadoClass, getIconByFiscalizacaoTipo} from "../../../../../assets/FiscaizacaoUtil";

export default {
    name: "ResumoFiscalizacoesCard",
    data() {
        return {
            offset: 10,
            page: 1,
            totalCount: 0,
            fiscalizacoes: [],
            currentFilter: {},
            loading: true,
            tipoResumoSelecionado: null,
        }
    },
    props:{
        resumo:null,
    },
    mounted() {
        this.getFiscalizacoesPendentes();
    },
    watch:{
        tipoResumoSelecionado(value){
            this.page = 1
            switch (value) {
                case 1:
                    this.getFiscalizacoesPendentes();
                    break
                case 2:
                    this.getFiscalizacoesFuturas();
                    break;
                default:
                    this.fiscalizacoes = []
            }
        }
    },
    methods:{
        getItemIcon(tipo){
            return getIconByFiscalizacaoTipo(tipo);
        },
        getFinalizadoClass(fiscalizacao){
            return getFinalizadoClass(fiscalizacao);
        },
        getFiscalizacoesPendentes(){
            this.loading = true;

            this.$apollo.query({
                query: gql`query($page:Int, $offset:Int) {
                    fiscalizacoesPendentes(page: $page, offset: $offset) {
                        totalCount
                        fiscalizacoes:itens{
                            id
                            dataLimite
                            tipo{
                                nome
                            }
                            financiamento{
                                mutuario
                                agencia {
                                    id
                                    codigo
                                    cidade {
                                        nome
                                        estado {
                                            sigla
                                        }
                                    }
                                }
                                empreendimento {
                                    descricao
                                }
                                valor
                            }
                        }
                    }
                }`,
                variables: {
                    page: this.page - 1,
                    offset: this.offset
                },
            }).then((result) => {
                this.fiscalizacoes = result.data.fiscalizacoesPendentes.fiscalizacoes;
                this.totalCount = result.data.fiscalizacoesPendentes.totalCount;
                this.loading = false;
            });
        },
        getFiscalizacoesFuturas(){
            this.loading = true;

            this.$apollo.query({
                query: gql`query($page:Int, $offset:Int) {
                    fiscalizacoesFuturas(page: $page, offset: $offset) {
                        totalCount
                        fiscalizacoes:itens{
                            id
                            dataLimite
                            tipo{
                                nome
                            }
                            financiamento{
                                mutuario
                                agencia {
                                    id
                                    codigo
                                    cidade {
                                        nome
                                        estado {
                                            sigla
                                        }
                                    }
                                }
                                empreendimento {
                                    descricao
                                }
                                valor
                            }
                        }
                    }
                }`,
                variables: {
                    page: this.page - 1,
                    offset: this.offset
                },
            }).then((result) => {
                this.fiscalizacoes = result.data.fiscalizacoesFuturas.fiscalizacoes;
                this.totalCount = result.data.fiscalizacoesFuturas.totalCount;
                this.loading = false;
            });
        },
        pageChanged(value){
            switch (this.tipoResumoSelecionado) {
                case 1:
                    this.getFiscalizacoesPendentes();
                    break
                case 2:
                    this.getFiscalizacoesFuturas();
                    break;
                default:
                    this.fiscalizacoes = []
            }
        },
    }
}
</script>

<style>
.resumo-fiscalizacoes-card .v-pagination__item,
.resumo-fiscalizacoes-card .v-pagination__navigation{
    font-size: 13px;
    height: 26px;
    min-width: 26px;
}

.resumo-fiscalizacoes-table a{
    text-decoration: none;
}

.resumo-fiscalizacoes-table a:hover{
    text-decoration: underline;
}
</style>