<template>
    <v-dialog v-model="dialog" content-class="fill-height"  persistent max-width="900px">
        <v-card class="fill-height d-flex flex-column">
            <v-card-title>
                <span class="headline">Dividir Fiscalizações</span>
            </v-card-title>
            <v-card-text class="pb-0 flex-grow-1 overflow-y-auto d-flex flex-column" style="">
                <v-row class="flex-grow-0">
                    <v-col cols="5" class="pt-0">
                        <v-menu v-model="monthlyMenu"
                                :close-on-content-click="false"
                                transition="scale-transition"
                                offset-y
                                max-width="290px">
                            <template v-slot:activator="{ on }">
                                <v-text-field
                                        v-model="monthlyFormated"
                                        label="Período"
                                        prepend-icon="event"
                                        readonly hide-details
                                        v-on="on"
                                ></v-text-field>
                            </template>
                            <v-date-picker v-model="month"
                                           type="month" no-title
                                           locale="pt-br"
                                           @input="monthlyMenu = false">
                            </v-date-picker>
                        </v-menu>
                        <div class="mt-2" v-if="fiscalizacoesTotalCount > 0">
                            <span class="subtitle-2 font-weight-regular">{{fiscalizacoes.length}} fiscalizações encontradas</span>
                        </div>
                    </v-col>
                </v-row>
                <v-row no-gutters class="flex-grow-1 overflow-hidden" style="">
                    <v-col cols="4" class="overflow-auto fill-height pr-0 " style="">
                        <v-list two-line v-if="usuarios && fiscalizacoesRestante.length > 0" class="py-0" style="position: sticky; top: 0; z-index: 1">
                            <v-list-item-group>
                                <v-list-item dark class="deep-orange" active-class="white&#45;&#45;text" v-if="fiscalizacoesRestante.length > 0"
                                             @click="fiscalizacoesDoUsuario = fiscalizacoesRestante; usuarioSelecionado = null">
                                    <v-list-item-content>
                                        <v-list-item-title>Restante</v-list-item-title>
                                        <v-list-item-subtitle>{{fiscalizacoesRestante.length}} fiscalizações</v-list-item-subtitle>
                                    </v-list-item-content>
                                </v-list-item>
                                <v-divider ></v-divider>
                            </v-list-item-group>
                        </v-list>
                        <v-list three-line v-if="usuarios && usuarios.length > 0" class="py-0">
                            <v-list-item-group v-model="usuarioSelecionado">

                                <template v-for="(usuario, index) in usuarios">
                                    <v-list-item @click="fiscalizacoesDoUsuario = usuario.fiscalizacoesAtribuidas.concat(usuario.fiscalizacoes).concat(usuario.fiscalizacoesFinalizadas)">
                                        <v-list-item-avatar color="blue darken-1">
                                            <usuario-avatar :usuario="usuario" />
                                        </v-list-item-avatar>

                                        <v-list-item-content>
                                            <v-list-item-title>{{usuario.nome}} {{usuario.sobrenome}}</v-list-item-title>
                                            <v-list-item-subtitle v-if="usuario.fiscalizacoesFinalizadas">{{usuario.fiscalizacoesFinalizadas.length}} fiscalizações finalizadas</v-list-item-subtitle>
                                            <v-list-item-subtitle v-if="usuario.fiscalizacoesAtribuidas">{{usuario.fiscalizacoesAtribuidas.length}} fiscalizações já atribuídas</v-list-item-subtitle>
                                            <v-list-item-subtitle>{{usuario.fiscalizacoes.length}} fiscalizações novas</v-list-item-subtitle>
                                        </v-list-item-content>

                                        <v-list-item-action>
                                            <v-checkbox v-model="usuariosSelecionados" color="primary" :value="index"></v-checkbox>
                                        </v-list-item-action>
                                    </v-list-item>
                                    <v-divider ></v-divider>
                                </template>
                            </v-list-item-group>

                        </v-list>
                    </v-col>
                    <v-col cols="8" class="overflow-auto fill-height pr-0" >
                        <v-list v-if="fiscalizacoesDoUsuario && fiscalizacoesDoUsuario.length > 0">
                            <template v-for="(fiscalizacao, index) in fiscalizacoesDoUsuario">
                                <v-list-item>
                                    <v-list-item-content>
                                        <v-list-item-title v-html="fiscalizacao.financiamento.mutuario"></v-list-item-title>
                                    </v-list-item-content>
                                    <v-list-item-action>
                                        <v-menu bottom left v-if="!fiscalizacao.dataFiscalizacao">
                                            <template v-slot:activator="{ on }">
                                                <v-btn icon v-on="on">
                                                    <v-icon>mdi-dots-vertical</v-icon>
                                                </v-btn>
                                            </template>

                                            <v-list>
                                                <v-subheader>Mover para</v-subheader>
                                                <template v-for="(usuario, i) in usuarios">
                                                    <v-list-item :key="i" @click="moverFiscalizacaoParaUsuario(index, usuario)" v-if="usuariosSelecionados.includes(i)" :disabled="usuarioSelecionado === i">
                                                        <v-list-item-title>{{ usuario.nome }}</v-list-item-title>
                                                    </v-list-item>
                                                </template>
                                            </v-list>
                                        </v-menu>

                                        <v-chip v-if="fiscalizacao.dataFiscalizacao" x-small label color="green" text-color="white" class="font-weight-bold px-2">FINALIZADO</v-chip>
                                    </v-list-item-action>

                                </v-list-item>

                                <v-divider ></v-divider>
                            </template>
                        </v-list>
                        <div v-else-if="loading === false && (fiscalizacoesDoUsuario === null || fiscalizacoesDoUsuario.length === 0)" class="text-center pa-6">
                            <h1 class="display-3 mb-4">Ops!</h1>
                            <span class="headline font-weight-light">Nenhum resultado encontrado!</span>
                        </div>
                    </v-col>
                </v-row>
            </v-card-text>
            <v-card-actions class="">
                <div class="pa-3" style="width: 100%">
                    <v-btn text @click="cancel">Cancelar</v-btn>

                    <div class="float-right">
                        <v-btn :disabled="fiscalizacoesTotalCount === 0" text color="primary" @click="save">Salvar</v-btn>
                    </div>

                </div>
            </v-card-actions>
            <v-overlay absolute :value="loading" color="white">
                <v-progress-circular :size="100" :width="7" color="deep-orange" indeterminate></v-progress-circular>
            </v-overlay>
        </v-card>

    </v-dialog>
</template>

<script>
    // @ts-ignore
    import UsuarioAvatar from "../../../components/UsuarioAvatar";
    import gql from "graphql-tag";
    import {AreaQueries} from "../../../queries/AreaQueries";
    import {FiscalizacaoQueries} from "../../../queries/FiscalizacaoQueries";

    export default {
        name: "DividirFiscalizacoesDialog",
        components: {
            UsuarioAvatar
        },
        watch: {
            month: {
                handler(value) {
                    if(this.usuarios.length > 0){
                        this.getFiscalizacoes(value);
                    }
                },
                immediate: true
            },
            usuariosSelecionados(value){
                if(!this.loading){
                    this.dividirFiscalizacoes();
                }
            },
        },
        computed: {
            monthlyFormated: {
                get() {
                    return this.month ? this.$moment(this.month).format('MMMM [de] YYYY') : '';
                },
                set(newValue) {
                    if (newValue) {
                        this.month = newValue;
                    }
                },
            },
        },
        data() {
            return {
                dialog: false,
                usuarios: [],
                monthlyMenu: false,
                month: this.$moment(new Date()).format('YYYY-MM'),
                loading: true,
                usuariosSelecionados: [],
                fiscalizacoes: [],
                fiscalizacoesDoUsuario: [],
                fiscalizacoesRestante: [],
                fiscalizacoesTotalCount: 0,
                usuarioSelecionado: 0,
                restanteSelecionado: false,
            }
        },
        methods: {
            openDialog() {
                this.dialog = true;
                this.month = this.$moment(new Date()).format('YYYY-MM');
                this.getUsuarios().then(() => {
                    this.getFiscalizacoes(this.month);
                })
            },
            closeDialog() {
                this.dialog = false;
                this.usuariosSelecionados = [];
                this.fiscalizacoes = [];
                this.fiscalizacoesDoUsuario = [];
                this.fiscalizacoesRestante = [];
                this.fiscalizacoesTotalCount = 0;
                this.usuarioSelecionado = 0;
            },
            save() {
                if(this.fiscalizacoesRestante.length > 0){
                    this.$dialog.error({
                        text: 'Ainda tem fiscalizações não atribuidas',
                        title: 'Erro',
                        actions: {yes: {text: 'Ok',},},
                    });
                    return;
                }

                this.loading = true;
                let fiscalizacoesBody = [];
                this.usuarios.filter((usuario, index) => { return this.usuariosSelecionados.includes(index) }, this)
                    .forEach(usuario => {
                        let fiscalizacoes = usuario.fiscalizacoes.concat(usuario.fiscalizacoesAtribuidas);
                        fiscalizacoes = fiscalizacoes.map(fiscalizacao => {
                            return {
                                id: fiscalizacao.id,
                                supervisedBy: {id: usuario.id}
                            }
                        });
                        fiscalizacoesBody = fiscalizacoesBody.concat(fiscalizacoes);
                    });

                this.$apollo.mutate({
                    mutation: FiscalizacaoQueries.atribuirFiscalizacoes,
                    variables: {
                        fiscalizacoes: fiscalizacoesBody,
                    },

                }).then((result) => {
                    this.$emit('saved');
                    this.loading = false;
                    this.closeDialog();
                    this.$notify({text: 'Fiscalizações atribuidas com sucesso!', type: 'success'});
                }).catch(() => {
                    this.loading = false;
                });
            },
            cancel() {
                this.closeDialog();
            },
            async getUsuarios() {
                this.loading = true;

                return this.$apollo.query({
                    query: gql`query {
                            usuarios{
                                id
                                nome
                                sobrenome
                                imagem{
                                    id
                                }
                            }
                        }`,
                }).then((result) => {
                    this.usuarios = result.data.usuarios.map((usuario, index) => {
                        this.usuariosSelecionados.push(index);
                        usuario.fiscalizacoes = [];
                        return usuario;
                    });
                });
            },
            getFiscalizacoes(yearMonth) {
                this.loading = true;

                yearMonth = yearMonth.replace('-', '/');
                const after = yearMonth + '/01';
                const before = yearMonth + '/' + this.$moment(yearMonth, 'YYYY/MM').daysInMonth();

                this.$apollo.query({
                    query: gql`query ($filter: SearchFilterInput!, $page: Int, $offset: Int){
                        searchFiscalizacoes(filter: $filter, page: $page, offset: $offset){
                            totalCount
                            fiscalizacoes:itens{
                                id
                                dataFiscalizacao
                                financiamento{
                                    mutuario
                                }
                                supervisedBy{
                                    id
                                }
                            }
                        }
                    }`,
                    variables: {
                        filter: {
                            searchValue: null,
                            searchCriteria: null,
                            itens: [{
                                name: "data-limite",
                                dateData: {
                                    before: new Date(before),
                                    after: new Date(after)
                                }
                            }],
                        },
                        page: 0,
                        offset: 1000
                    },
                }).then((result) => {
                    this.fiscalizacoes = result.data.searchFiscalizacoes.fiscalizacoes;
                    this.fiscalizacoesTotalCount = result.data.searchFiscalizacoes.totalCount;

                    this.dividirFiscalizacoes();

                    this.usuarios.forEach(usuario => {
                        this.fiscalizacoesTotalCount -= usuario.fiscalizacoesFinalizadas.length;
                    });

                    this.fiscalizacoesDoUsuario = this.fiscalizacoesRestante.length > 0 ? this.fiscalizacoesRestante : this.usuarios[0].fiscalizacoesAtribuidas.concat(this.usuarios[0].fiscalizacoes).concat(this.usuarios[0].fiscalizacoesFinalizadas);
                    this.usuarioSelecionado = this.fiscalizacoesRestante.length > 0 ? null : 0;

                    this.loading = false;
                });
            },
            dividirFiscalizacoes(){
                this.fiscalizacoesRestante = this._.cloneDeep(this.fiscalizacoes);

                // Pega os ja pertencentes
                this.usuarios.forEach((usuario, index) => {
                    usuario.fiscalizacoesFinalizadas = this._.remove(this.fiscalizacoesRestante, fiscalizacao => {
                        return fiscalizacao.dataFiscalizacao && fiscalizacao.supervisedBy && fiscalizacao.supervisedBy.id === usuario.id;
                    }, this);

                    if(this.usuariosSelecionados.includes(index)) {
                        usuario.fiscalizacoesAtribuidas = this._.remove(this.fiscalizacoesRestante, function (fiscalizacao) {
                            return fiscalizacao.supervisedBy && fiscalizacao.supervisedBy.id === usuario.id;
                        });
                    }else{
                        usuario.fiscalizacoesAtribuidas = [];
                    }
                }, this);

                let porUsuario = Math.floor(this.fiscalizacoes.length / this.usuariosSelecionados.length);

                this.usuarios.forEach((usuario, index) => {
                    if(this.usuariosSelecionados.includes(index)){
                        usuario.fiscalizacoes = this.fiscalizacoesRestante.splice(0, porUsuario - usuario.fiscalizacoesAtribuidas.length);
                    }else{
                        usuario.fiscalizacoes = [];
                    }
                }, this);
            },
            moverFiscalizacaoParaUsuario(fiscalizacaoIndex, usuario){
                let fiscalizacao = null;
                if(this.usuarioSelecionado !== undefined){
                    fiscalizacao = this.usuarios[this.usuarioSelecionado].fiscalizacoes.splice(fiscalizacaoIndex,1)[0];
                    this.fiscalizacoesDoUsuario.splice(fiscalizacaoIndex,1)[0];
                }else{
                    fiscalizacao = this.fiscalizacoesRestante.splice(fiscalizacaoIndex, 1)[0];
                }
                usuario.fiscalizacoes.push(fiscalizacao);
            }
        }
    }
</script>

<style scoped>

</style>