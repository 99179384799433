<template>
    <v-dialog v-model="dialog" scrollable persistent max-width="900px">
        <v-card>
            <v-card-title>
                <span class="headline" style="width: 100%">Baixar Arquivos do Banco</span>
                <div class="mt-4" v-if="!finalizado" style="width: 100%">
                    <v-row no-gutters>
                        <v-col style="max-width: 200px">
                            <v-menu v-model="monthlyMenu"
                                    :close-on-content-click="false"
                                    transition="scale-transition"
                                    offset-y :disabled="baixando"
                                    max-width="290px">
                                <template v-slot:activator="{ on }">
                                    <v-text-field
                                        v-model="monthlyFormated"
                                        label="Período"
                                        prepend-icon="event"
                                        readonly hide-details
                                        v-on="on"
                                    ></v-text-field>
                                </template>
                                <v-date-picker v-model="month"
                                               type="month" no-title
                                               locale="pt-br"
                                               @input="monthlyMenu = false">
                                </v-date-picker>
                            </v-menu>
                        </v-col>
                        <v-col align-self="end">
                            <v-btn class="ml-2" color="primary" x-small
                                   :disabled="baixando" @click="marcarDesmarcarTodos">
                                Marcar/Desmarcar todos
                            </v-btn>
                        </v-col>
                    </v-row>

                    <span v-if="fiscalizacoes.length > 0" class="subtitle-2 font-weight-regular">{{fiscalizacoes.length}} resultados encontrados</span>
                </div>
            </v-card-title>
            <v-card-text class="pb-0 " style="">
                <template v-if="!finalizado">
                    <v-list two-line v-if="fiscalizacoes.length > 0">
                        <v-list-item-group v-model="fiscalizacoesSelecionadas" multiple>
                            <template v-for="(fiscalizacao) in fiscalizacoes">
                                <v-list-item :key="fiscalizacao.id" >
                                    <template v-slot:default="{ active, }">
                                        <v-checkbox v-if="!baixando"
                                            :input-value="active"
                                            color="primary"
                                        ></v-checkbox>
                                        <v-list-item-avatar :class="!baixando ? 'mx-4' : ''">
                                            <v-icon class="light-green lighten-3" v-text="getItemIcon(fiscalizacao.tipo)"></v-icon>
                                        </v-list-item-avatar>
                                        <v-list-item-content>
                                            <v-list-item-title>{{fiscalizacao.financiamento.mutuario}}</v-list-item-title>
                                            <v-list-item-subtitle v-if="!baixando">
                                                <v-chip x-small color="orange" label class="text-uppercase mr-1" dark
                                                        v-for="arquivoTag in getTagsArquivos(fiscalizacao.arquivos)">
                                                    <b>{{arquivoTag}}</b>
                                                </v-chip>
                                            </v-list-item-subtitle>
                                            <v-list-item-subtitle>
                                                <div>
                                                    <v-chip v-if="baixando && fiscalizacao.status === 0" x-small label color="grey lighten-1" text-color="white" class="mr-1 font-weight-bold px-2">
                                                        AGUARDANDO
                                                    </v-chip>
                                                    <v-chip v-if="fiscalizacao.status === 1" x-small label color="blue" text-color="white" class="mr-1 font-weight-bold px-2">
                                                        BAIXANDO...
                                                    </v-chip>
                                                    <!--<template v-if="fiscalizacao.status === 2" v-for="(arquivo, index) in fiscalizacao.arquivos" >
                                                        <v-chip x-small label color="green" text-color="white" class="mr-1 font-weight-bold px-2">
                                                            {{arquivo}}
                                                        </v-chip>
                                                    </template>-->
                                                    <div class="d-flex" v-if="fiscalizacao.status === 2">
                                                        <template v-for="arquivoStatus in fiscalizacao.arquivosStatus">
                                                            <v-sheet outlined style="margin: 1px" rounded class="mr-1  pa-1">
                                                                <span class="caption d-block">{{arquivoStatus.arquivo.nome}}</span>
                                                                <v-chip x-small dark label color="blue" class="mr-1" v-for="tag in arquivoStatus.arquivo.tags">
                                                                    <b>{{tag.nome}}</b>
                                                                </v-chip>
                                                                <v-chip v-if="arquivoStatus.status === 'novo'" x-small dark label color="green">
                                                                    <b>Novo</b>
                                                                </v-chip>
                                                                <v-chip v-if="arquivoStatus.status === 'sobrescrito'" x-small dark label color="orange">
                                                                    <b>Sobrescrito</b>
                                                                </v-chip>
                                                            </v-sheet>
                                                        </template>
                                                    </div>
                                                    <v-chip v-if="fiscalizacao.status === 3" x-small label color="orange lighten-1" text-color="white" class="mr-1 font-weight-bold px-2">
                                                        NENHUM ARQUIVO ENCONTRADO
                                                    </v-chip>
                                                    <v-chip v-if="fiscalizacao.status === 4" x-small label color="red" text-color="white" class="mr-1 font-weight-bold px-2">
                                                        ERRO
                                                    </v-chip>
                                                </div>
                                            </v-list-item-subtitle>
                                        </v-list-item-content>
                                        <v-list-item-action v-if="!baixando">
                                            <v-list-item-action-text>N. Solicitação: {{fiscalizacao.numeroSolicitacao}}</v-list-item-action-text>
                                            <v-list-item-action-text>Operação: {{fiscalizacao.financiamento.operacao+(fiscalizacao.financiamento.operacaoDigito ? '-'+fiscalizacao.financiamento.operacaoDigito : '')}}</v-list-item-action-text>
                                        </v-list-item-action>
                                        <v-list-item-action>
                                        <v-btn icon @click.stop="" :to="{ name: 'fiscalizacao_view', params: { id: parseInt(fiscalizacao.id) }}" target="_blank">
                                            <v-icon color="grey lighten-1">mdi-share</v-icon>
                                        </v-btn>
                                    </v-list-item-action>
                                    </template>
                                </v-list-item>

                                <v-divider ></v-divider>
                            </template>
                        </v-list-item-group>
                    </v-list>
                    <div v-else-if="loading === false && (fiscalizacoes === null || fiscalizacoes.length === 0)" class="text-center pa-6">
                        <span class="headline font-weight-light">Todas as fiscalizações desse período já possuem o laudo do banco!</span>
                    </div>
                </template>
                <div v-else class="d-flex flex-column align-center justify-center" style="height: 200px">
                    <span class="subtitle-1 d-block mb-2">Processo finalizado com sucesso!</span>
                    <v-btn  color="primary" @click="verRelatorio">Ver resultado</v-btn>
                </div>
            </v-card-text>
            <v-card-actions class="">
                <div class="pa-3" style="width: 100%">
                    <v-btn text v-if="!baixando" @click="cancel">Cancelar</v-btn>
                    <div class="float-right">
                        <v-btn v-if="!baixando" text color="primary" @click="inicia" :disabled="fiscalizacoesSelecionadas.length === 0">Iniciar</v-btn>
                        <v-btn v-else text color="primary" @click="closeDialog">Fechar</v-btn>
                    </div>
                </div>
            </v-card-actions>
            <v-overlay absolute :value="loading" color="white">
                <v-progress-circular :size="100" :width="7" color="deep-orange" indeterminate></v-progress-circular>
            </v-overlay>
        </v-card>
        <importar-arquivos-relatorio-dialog ref="importarArquivosRelatorioDialog" />
    </v-dialog>
</template>

<script>
import gql from "graphql-tag";
import {getIconByFiscalizacaoTipo} from "../../../assets/FiscaizacaoUtil";
import ImportarArquivosRelatorioDialog from "../../sistema/ImportarArquivosRelatorioDialog";

export default {
    name: "BaixarArquivosBancoDialog",
    components: {ImportarArquivosRelatorioDialog},
    data() {
        return {
            dialog: false,
            loading: false,
            baixando: false,
            fiscalizacoes: [],
            fiscalizacoesSelecionadas: [],
            monthlyMenu: false,
            month: this.$moment(new Date()).format('YYYY-MM'),
            finalizado: false,
            tarefaLogId: null,
        }
    },
    watch: {
        month: {
            handler(value) {
                if(!this.dialog){
                    return
                }
                this.fiscalizacoesSelecionadas = [];
                this.getFiscalizacoes(value);
            },
            immediate: true
        },
    },
    computed: {
        monthlyFormated: {
            get() {
                return this.month ? this.$moment(this.month).format('MMMM [de] YYYY') : '';
            },
            set(newValue) {
                if (newValue) {
                    this.month = newValue;
                }
            },
        },
    },
    apollo:{
        $subscribe:{
            tarefaAtualizada:{
                query: gql`subscription {
                        tarefa:tarefaLogPublisher{
                            id
                            nome
                            inicio
                            fim
                            log
                            disparadoPor{
                                id
                                nome
                            }
                            concluidoComSucesso
                        }
                    }`,
                result ({ data }) {
                    let tarefa = data.tarefa;
                    if(this.tarefaLogId === tarefa.id){
                        if(tarefa.fim){
                            if(tarefa.concluidoComSucesso){
                                this.finalizado = true;
                                this.$emit("completed", null);
                            }else{
                                this.closeDialog()
                            }
                        }
                    }
                },
            },
            tarefaProgresso:{
                query: gql`subscription {
                    progresso:tarefaImportaArquivosProgressPublisher{
                        total
                        current
                        fiscalizacaoId
                        status
                        arquivosStatus{
                            arquivo{
                                id
                                nome
                                tags{
                                    id
                                    nome
                                }
                            }
                            status
                        }
                    }
                }`,
                result ({ data }) {
                    let progresso = data.progresso;

                    let fiscalizacao = this.fiscalizacoes.find(fiscalizacao => fiscalizacao.id === progresso.fiscalizacaoId);
                    if(fiscalizacao){
                        if(progresso.status === "start"){
                            fiscalizacao.status = 1
                        } else if(progresso.status === "complete"){
                            if(progresso.arquivosStatus && progresso.arquivosStatus.length > 0){
                                fiscalizacao.status = 2
                                fiscalizacao.arquivosStatus = progresso.arquivosStatus;
                            }else{
                                fiscalizacao.status = 3
                            }
                        } else if(progresso.status === "erro"){
                            fiscalizacao.status = 4
                        }

                    }
                },
            }
        }
    },
    methods: {
        openDialog() {
            this.dialog = true;
            this.month = this.$moment(new Date()).format('YYYY-MM');
            this.fiscalizacoes = [];
            this.fiscalizacoesSelecionadas = [];
            this.baixando = false;
            this.finalizado = false;
            this.tarefaLogId = null;
            this.checkTarefaEmExecucao().then(() => {
                this.getFiscalizacoes(this.month);
            }).catch(() => {
                this.$dialog.warning({
                    title: 'Atenção',
                    text: 'Já existe uma tarefa desse tipo em execução!',
                    actions: {yes: {text: 'Ok'}},
                })
                this.closeDialog();
            })
        },
        closeDialog() {
            this.dialog = false;
            this.loading =  false;
            this.baixando = false;
            this.fiscalizacoes = [];
            this.fiscalizacoesSelecionadas = [];
        },
        cancel(){
            this.closeDialog();
        },
        getItemIcon(tipo){
            return getIconByFiscalizacaoTipo(tipo);
        },
        marcarDesmarcarTodos(){
            if(this.fiscalizacoesSelecionadas.length < this.fiscalizacoes.length){
                //marcar
                this.fiscalizacoes.forEach((value, index) => {
                    this.fiscalizacoesSelecionadas.push(index)
                })
            }else{
                //desmarcar
                this.fiscalizacoesSelecionadas = []
            }
        },
        getTagsArquivos(arquivos){
            let tags = [];
            for(let arquivo of arquivos){
                if(arquivo.tags.some(t => t.nome === 'Laudo')){
                    tags.push("Sobrescreve Laudo")
                }

                if(arquivo.tags.some(t => t.nome === 'Coordenadas')){
                    tags.push("Sobrescreve Coordenadas")
                }
            }
            return tags;
        },
        checkTarefaEmExecucao(){
            return new Promise((resolve, reject) => {
                this.$apollo.query({
                    query: gql`query ($tipo: String!){
                    tarefa:verificaTarefaEmExecucaoPorTipo(tipo: $tipo){
                        id
                    }
                }`,
                    variables: {
                        tipo: "importa-arquivos",
                    },
                }).then((result) => {
                    if(result.data.tarefa){
                        reject()
                    }else{
                        resolve();
                    }
                })
            })
        },
        getFiscalizacoes(yearMonth){
            this.loading = true;
            yearMonth = yearMonth.replace('-', '/');
            const after = yearMonth + '/01';
            const before = yearMonth + '/' + this.$moment(yearMonth, 'YYYY/MM').daysInMonth();

            this.$apollo.query({
                query: gql`query ($afterDate: LocalDate!, $beforeDate: LocalDate!){
                    fiscalizacoes:fiscalizacoesParaImportarArquivos(afterDate: $afterDate, beforeDate: $beforeDate){
                        id
                        numeroSolicitacao
                        tipo{
                            nome
                        }
                        financiamento{
                            mutuario
                            cedula
                            operacao
                            operacaoDigito
                        }
                        arquivos{
                            id
                            nome
                            tags{
                                id
                                nome
                            }
                        }
                    }
                }`,
                variables: {
                    beforeDate: new Date(before).getTime(),
                    afterDate: new Date(after).getTime()
                },
            }).then((result) => {
                this.fiscalizacoes = result.data.fiscalizacoes.map((fiscalizacao) => {
                    fiscalizacao.status = 0;
                    //fiscalizacao.arquivos = [];
                    return fiscalizacao;
                })//.reverse().splice(18, 5);
            }).finally(()=>{
                this.loading = false;
            })
        },
        inicia(){
            this.$dialog.confirm({
                title: 'Atenção',
                text: 'Está tarefa será executada em segundo plano.\n' +
                    'Você pode fechar essa janela e continuar o que estava fazendo.',
                actions: {yes: {text: 'Ok'}},
            }).then((result) => {
                this.fiscalizacoes = this._.pullAt(this.fiscalizacoes, this.fiscalizacoesSelecionadas);
                this.fiscalizacoesSelecionadas = [];
                this.baixando = true;
                this.$apollo.mutate({
                    mutation: gql`mutation($fiscalizacoesIds: [Int]!) {
                        tarefa:iniciaTarefaImportaArquivos(fiscalizacoes_ids: $fiscalizacoesIds){
                            id
                            nome
                            tipo
                            inicio
                            fim
                            concluidoComSucesso
                            disparadoPor{
                                id
                            }
                        }
                    }`,
                    variables:{
                        fiscalizacoesIds: this.fiscalizacoes.map((fiscalizacao) => fiscalizacao.id)
                    }
                }).then((result) => {
                    this.tarefaLogId = result.data.tarefa.id;
                    //tarefaLog.tarefasFilhas.unshift(result.data.tarefa)
                });
            })

        },
        verRelatorio(){
            this.$refs.importarArquivosRelatorioDialog.openDialog(this.tarefaLogId);
        }
    }
}
</script>

<style scoped>

</style>