<template>
    <v-dialog v-model="dialog" scrollable persistent max-width="900px">
        <v-card>
            <v-card-title>
                <span class="headline" style="width: 100%">Baixar Coordenadas</span>
                <div class="mt-4" v-if="!finalizado">
                    <span v-if="fiscalizacoes.length > 0" class="subtitle-2 font-weight-regular">{{fiscalizacoes.length}} resultados encontrados</span>
                </div>
            </v-card-title>
            <v-card-text class="pb-0 " style="">
                <template v-if="!finalizado">
                    <v-list two-line v-if="fiscalizacoes.length > 0">
                        <template v-for="(fiscalizacao) in fiscalizacoes">
                            <v-list-item :key="fiscalizacao.id" >
                                <v-list-item-avatar>
                                    <v-icon class="light-green lighten-3" v-text="getItemIcon(fiscalizacao.tipo)"></v-icon>
                                </v-list-item-avatar>
                                <v-list-item-content>
                                    <v-list-item-title>{{fiscalizacao.financiamento.mutuario}}</v-list-item-title>
                                    <v-list-item-subtitle>
                                        <div>
                                            <v-chip v-if="baixando && fiscalizacao.status === 0" x-small label color="grey lighten-1" text-color="white" class="mr-1 font-weight-bold px-2">
                                                AGUARDANDO
                                            </v-chip>
                                            <v-chip v-if="fiscalizacao.status === 1" x-small label color="blue" text-color="white" class="mr-1 font-weight-bold px-2">
                                                BAIXANDO...
                                            </v-chip>
                                            <v-chip v-if="fiscalizacao.status === 2" x-small label color="green" text-color="white" class="mr-1 font-weight-bold px-2">
                                                BAIXADO
                                            </v-chip>
                                            <v-chip v-if="fiscalizacao.status === 3" x-small label color="orange lighten-1" text-color="white" class="mr-1 font-weight-bold px-2">
                                                NÃO ENCONTRADO
                                            </v-chip>
                                            <v-chip v-if="fiscalizacao.status === 4" x-small label color="orange lighten-1" text-color="white" class="mr-1 font-weight-bold px-2">
                                                SEM COORDENADS
                                            </v-chip>
                                            <v-chip v-if="fiscalizacao.status === 5" x-small label color="red" text-color="white" class="mr-1 font-weight-bold px-2">
                                                ERRO
                                            </v-chip>
                                        </div>
                                    </v-list-item-subtitle>
                                </v-list-item-content>
                                <v-list-item-action>
                                    <v-list-item-action-text >Data Limite: <b>{{new Date(fiscalizacao.dataLimite) | moment("DD MMM YYYY")}}</b></v-list-item-action-text>
                                </v-list-item-action>
                                <v-list-item-action>
                                    <v-btn icon :to="{ name: 'fiscalizacao_view', params: { id: parseInt(fiscalizacao.id) }}" target="_blank">
                                        <v-icon color="grey lighten-1">mdi-share</v-icon>
                                    </v-btn>
                                </v-list-item-action>

                            </v-list-item>

                            <v-divider ></v-divider>
                        </template>
                    </v-list>
                </template>
                <div v-else class="d-flex flex-column align-center justify-center" style="height: 200px">
                    <span class="subtitle-1 d-block mb-2">Processo finalizado com sucesso!</span>
                    <v-btn  color="primary" @click="verRelatorio">Ver resultado</v-btn>
                </div>
            </v-card-text>
            <v-card-actions class="">
                <div class="pa-3" style="width: 100%">
                    <v-btn text v-if="!baixando" @click="cancel">Cancelar</v-btn>
                    <div class="float-right">
                        <v-btn v-if="!baixando" text color="primary" @click="inicia" :disabled="fiscalizacoes.length === 0">Iniciar</v-btn>
                        <v-btn v-else text color="primary" @click="closeDialog">Fechar</v-btn>
                    </div>
                </div>
            </v-card-actions>
            <v-overlay absolute :value="loading" color="white">
                <v-progress-circular :size="100" :width="7" color="deep-orange" indeterminate></v-progress-circular>
            </v-overlay>
        </v-card>
<!--        <envia-laudos-banco-relatorio-dialog ref="enviaLaudosBancoRelatorioDialog" />-->
    </v-dialog>
</template>

<script>
import gql from "graphql-tag";
import {getIconByFiscalizacaoTipo} from "@/assets/FiscaizacaoUtil";

export default {
    name: "BaixarCoordenadasDialog",
    data() {
        return {
            dialog: false,
            loading: false,
            baixando: false,
            fiscalizacoes: [],
            finalizado: false,
            tarefaLogId: null,
        }
    },
    apollo:{
        $subscribe:{
            tarefaAtualizada:{
                query: gql`subscription {
                        tarefa:tarefaLogPublisher{
                            id
                            nome
                            inicio
                            fim
                            log
                            disparadoPor{
                                id
                                nome
                            }
                            concluidoComSucesso
                        }
                    }`,
                result ({ data }) {
                    let tarefa = data.tarefa;
                    if(this.tarefaLogId === tarefa.id){
                        if(tarefa.fim){
                            if(tarefa.concluidoComSucesso){
                                this.finalizado = true;
                                this.$emit("completed", null);
                            }else{
                                this.closeDialog()
                            }
                        }
                    }
                },
            },
            tarefaProgresso:{
                query: gql`subscription {
                    progresso:tarefaProcessaCoordenadasProgressPublisher{
                        total
                        current
                        fiscalizacaoId
                        status
                    }
                }`,
                result ({ data }) {
                    let progresso = data.progresso;

                    let fiscalizacao = this.fiscalizacoes.find(fiscalizacao => fiscalizacao.id === progresso.fiscalizacaoId);
                    if(fiscalizacao){
                        if(progresso.status === "start"){
                            fiscalizacao.status = 1
                        } else if(progresso.status === "success"){
                            fiscalizacao.status = 2
                        } else if(progresso.status === "nao_encontrado"){
                            fiscalizacao.status = 3
                        } else if(progresso.status === "sem_coordenadas"){
                            fiscalizacao.status = 4
                        } else if(progresso.status === "erro"){
                            fiscalizacao.status = 5
                        }

                    }
                },
            }
        }
    },
    methods: {
        openDialog() {
            this.dialog = true;
            this.fiscalizacoes = [];
            this.baixando = false;
            this.finalizado = false;
            this.tarefaLogId = null;
            this.checkTarefaEmExecucao().then(() => {
                this.getFiscalizacoes();
            }).catch(() => {
                this.$dialog.warning({
                    title: 'Atenção',
                    text: 'Já existe uma tarefa desse tipo em execução!',
                    actions: {yes: {text: 'Ok'}},
                })
                this.closeDialog();
            })
        },
        closeDialog() {
            this.dialog = false;
            this.loading =  false;
            this.baixando = false;
            this.fiscalizacoes = [];
        },
        cancel(){
            this.closeDialog();
        },
        getItemIcon(tipo){
            return getIconByFiscalizacaoTipo(tipo);
        },
        checkTarefaEmExecucao(){
            return new Promise((resolve, reject) => {
                this.$apollo.query({
                    query: gql`query ($tipo: String!){
                        tarefa:verificaTarefaEmExecucaoPorTipo(tipo: $tipo){
                            id
                        }
                    }`,
                    variables: {
                        tipo: "processa-coordenadas",
                    },
                }).then((result) => {
                    if(result.data.tarefa){
                        reject()
                    }else{
                        resolve();
                    }
                })
            })
        },
        getFiscalizacoes(){
            this.loading = true;

            this.$apollo.query({
                query: gql`query {
                    fiscalizacoes:fiscalizacoesPendentesSemCoordenadas{
                        id
                        dataLimite
                        tipo{
                            nome
                        }
                        financiamento{
                            mutuario
                        }
                    }
                }`,
            }).then((result) => {
                this.fiscalizacoes = result.data.fiscalizacoes.map((fiscalizacao) => {
                    fiscalizacao.status = 0;
                    return fiscalizacao;
                })//.reverse().splice(18, 5);
            }).finally(()=>{
                this.loading = false;
            })
        },
        inicia(){
            this.$dialog.confirm({
                title: 'Atenção',
                text: 'Está tarefa será executada em segundo plano.\n' +
                    'Você pode fechar essa janela e continuar o que estava fazendo.',
                actions: {yes: {text: 'Ok'}},
            }).then((result) => {
                this.baixando = true;
                this.$apollo.mutate({
                    mutation: gql`mutation($fiscalizacoesIds: [Int]!) {
                        tarefa:iniciaTarefaProcessaCoordenadas(fiscalizacoes_ids: $fiscalizacoesIds){
                            id
                            nome
                            tipo
                            inicio
                            fim
                            concluidoComSucesso
                            disparadoPor{
                                id
                            }
                        }
                    }`,
                    variables:{
                        fiscalizacoesIds: this.fiscalizacoes.map((fiscalizacao) => fiscalizacao.id)
                    }
                }).then((result) => {
                    this.tarefaLogId = result.data.tarefa.id;
                    //tarefaLog.tarefasFilhas.unshift(result.data.tarefa)
                });
            })

        },
    }
}
</script>

<style scoped>

</style>